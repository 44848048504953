
function Testimonials() {

    const reviews = [
        {
            name: "Gillian C",
            content: "My experience at Dragonfly Therapies was brilliant. Sarah shows a professional yet warm and relaxing atmosphere. After a health check I was offered a variety of depths of massage to suit my mood. I chose well and had an enjoyable and restorative massage. Certainly I will be returning for more of the same."
        },
        {
            name: "Sarah R",
            content: "Had a wonderful full body massage and haven't felt so pampered in ages! Sarah's technique was great and she made me feel really comfortable and relaxed. The atmosphere was calm and professional. Sarah puts you at ease and was welcoming and friendly. I wouldn't hesitate to recommend her and will be back soon."
        },
        {
            name: "Kevin M",
            content: "I highly recommend Dragonfly Therapies. I work in a high stress environment, on top of which my body has taken some knocks through the years which remind me of their presence all of the time. When I first attended Dragonfly Therapies, Sarah took the time to understand my needs and recognises the implications of my injuries and moulds my treatment plan around them. I see my sessions at Dragonfly Therapies now as essential to my ongoing sense of wellbeing."
        },
        {
            name: "Ian K",
            content: "I highly recommend Sarah at Dragonfly Therapies. Suffering from the general aches and pains that modern life brings, an all over massage leaves me feeling refreshed, more subtle and relaxed. This is now part of my regular routine to help me keep me fit and active."
        },
        {
            name: "Fiona S",
            content: "An excellent experience! Sarah listened to my requirements and applied the right amount of pressure. I left feeling relaxed and revived. Highly recommended. "
        }, 
        {
            name: "Isabelle C",
            content: "Sarah is a wonderful therapist, she makes you feel welcome and comfortable right from the start. Her attention to detail and considerate nature mean you really get the best out of your massage, even if you have any previous injuries or pressure points. I had a fantastic massage and would highly recommend her to friends, family and anyone who is looking for a way to relax and recharge."
        }

    ]

    return (
      <div className="Reviews">
        {reviews.map((r) => {
            return (
                <div key={r.name} className="ReviewItem">
                    <p className="ReviewItem__name">{r.name}</p>
                    <p className="ReviewItem__content"><span>"</span>{r.content}<span>"</span></p>
                </div>
            )
        })}
      </div>
    );
  }
  
  export default Testimonials;