import React, {useState} from "react";
import logo from './dragon-fly-logo.png';
import fhtLogo from './fht-logo.png';
import nyrLogo from './nyr-logo.png';
import Treatments from './components/treatments';
import Welcome  from "./components/welcome";
import Contact from "./components/contact";
import Testimonials from "./components/testimonials";
import Terms from "./components/terms";
import './App.css';

function App() {
  const [showSection, setShowSection] = useState('welcome');
  return (
    <div className="App">
      <header className="Header">
        <img src={logo} alt="logo" />
        <p className={showSection === 'welcome' ? "HeaderItem__active" : "HeaderItem"} onClick={() => setShowSection('welcome')}>Home</p>
        <p className={showSection === 'treatments' ? "HeaderItem__active" : "HeaderItem"} onClick={() => setShowSection('treatments')}>Treatments</p>
        <p className={showSection === 'contact' ? "HeaderItem__active" : "HeaderItem"} onClick={() => setShowSection('contact')}>Contact</p>
        <p className={showSection === 'reviews' ? "HeaderItem__active" : "HeaderItem"} onClick={() => setShowSection('reviews')}>Testimonials</p>
      </header>
      <main>
        {showSection === 'welcome' && <Welcome />}
        {showSection === 'treatments' && <Treatments />}
        {showSection === 'contact' && <Contact />}
        {showSection === 'reviews' && <Testimonials />}
        {showSection === 'terms' && <Terms />}
      </main>
      <footer className="Footer">
        <a target="_blank" rel="noreferrer" href="https://www.fht.org.uk/users/bankhead214115">
          <img src={fhtLogo} alt="fhtlogo" />
        </a>
        <a target="_blank" rel="noreferrer" href="https://uk.nyrorganic.com/shop/sarahbankhead/area/shop-online/category/shop/">
          <img src={nyrLogo} alt="nyrLogo" />
        </a>
        <p onClick={() => setShowSection('terms')}>Terms & Conditions</p>
      </footer>
    </div>
  );
}

export default App;
