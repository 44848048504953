
function Contact() {
    return (
      <div className="Contact">
        <div className="Contact__address">
            <p>Wall Garden Cottage</p>
            <p>Gravel Pit Lane,</p>
            <p>Lasham,</p>
            <p>Hampshire,</p>
            <p>GU34 5SD</p>
        </div>
        <p className="Contact__3">what3words: <span>///clap.woke.bluffing</span></p>
        <p className="Contact__phone">07903 800303</p>
        <a href="mailto:sarah@dragonflytherapies.net">sarah@dragonflytherapies.net</a>
      </div>
    );
  }
  
  export default Contact;