
function Terms() {

 


    return (
      <div className="Terms">
        <h3>TERMS & CONDITIONS</h3>
        <p>Thank you for choosing Dragonfly Therapies for your massage, and to provide the best service possible to our clients we have implemented the following policies.</p>
        <h3>Cancellation Policy</h3>
        <p>We respectfully request that you provide us with 24-hour notice of any schedule changes or cancellation requests. Please understand that when you cancel or miss your appointment without providing 24 hour notice we are often unable to fill that appointment time. This is an inconvenience to Dragonfly Therapies and also means our other clients miss the chance to receive services they need. For this reason, you will be charged 100% of the service fee, unless otherwise agreed.</p>
        <p>On the rare occasions that Dragonfly Therapies are unable to fulfil your treatment, we will notify you as soon as possible to reschedule or arrange a refund for your appointment.</p>
        <h3>Late Arrival Policy</h3>
        <p>We request that you arrive promptly for your appointment time to allow for your pre-treatment consultation. We understand that issues can arise that may cause you to be late. However, we ask that you call to inform us if this ever occurs, so we can do our best to accommodate you. Appointment times are reserved for each client, so in most cases we cannot exceed that reserved time without making the next client late. For this reason, arriving after your appointment time may result in loss of time from your massage, so that your session ends at the scheduled time. Full-service fees will be charged even when sessions are shortened due to late arrival. In return we will do our best to be on time, and if we are unable to do so we will add time to your session to make up the time lost (if possible).</p>
        <h3>Other Considerations</h3>
        <p>We understand that emergencies can arise, and illnesses do occur at inopportune times. If you have a high temperature, a known infection, or have experienced vomiting or diarrhoea within 24 hours prior to your appointment time, we request that you cancel your session.</p>
        <p>Bad weather may result in the need for late cancellations. We will do our best to give advanced notice if we are closing or need to cancel due to bad weather and we ask you to do the same. Please do not risk your own safety trying to make your appointment. Late cancellation due to emergency, illness, or inclement weather will generally not result in any missed session charges, but this is determined on a case-by-case basis. </p>
        <h3>Inappropriate Behaviour Policy </h3>
        <p>Massage therapy at Dragonfly Therapies is for relaxation and therapeutic purposes only. There is absolutely no sexual component to the massage. Any insinuation, joke, gesture, conversation, or request otherwise, will result in immediate termination of your session, and a refusal of any and all future services. You will be charged the full-service fee regardless of the length of your session. Depending on the behaviour exhibited, we may also file a report with the local authorities.</p>
      </div>
    );
  }
  
  export default Terms;