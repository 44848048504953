
function Welcome() {

    const p1 = `Hello and welcome to Dragonfly Therapies, I’m Sarah and I came to Complementary Therapy quite late in my working career.`;
    const p2 = `My work life had previously encompassed the veterinary, financial and aviation sectors with the latter years emersed in the corporate world and the challenging work life balance that often entails.`;
    const p3 = `However, when my late husband was diagnosed with bowel cancer and devastatingly passed away within four months, it rocked my world to the core and triggered a re-evaluation of everything.`;
    const p4 = `The experience that sparked my change in career direction was a complimentary massage offered by the Hospice where my late husband was treated, to carers of their patients.  I experienced the therapeutic, tension releasing power of massage and the amazing feeling of ‘time out’ it provided, in what was a very difficult and emotional period of my life.  This shaped my future career and is the reason why Dragonfly Therapies was born.`;
    const p5 = `I studied at Champney’s College, Tring, to gain the Anatomy & Physiology, Massage and Infection Prevention qualifications and I studied at Richdale's institute, Harrow, for my Reflexology diploma. I will be continuing to expand my skills and treatment portfolio for both my own continual personal development and to benefit my clients.`;
    const p6 = `I now offer my services in complementary therapy both privately and within the voluntary sector to help others to feel the benefit and healing magic of reflexology and massage.`;

    return (
      <div className="Welcome">
        <p>{p1}</p>
        <p>{p2}</p>
        <p>{p3}</p>
        <p>{p4}</p>
        <p>{p5}</p>
        <p>{p6}</p>
      </div>
    );
  }
  
  export default Welcome;