
function Treatments() {

  const info1 = `Reflexology and massage are wonderful ways to treat yourself to some ‘time out’ and much needed self-care. The benefits are numerous and include reducing tension in your muscles, enhancing your circulation, relieving stress, improving mental alertness, boosting your immune function, stimulating lymphatic drainage, improving joint mobility, helping to improve your sleep quality and easing muscle discomfort`
  const info2 = `You can opt for any of the following treatments, or we can work together to create a bespoke, tailor made treatment to meet your needs.`;
  const info3 = `Home visits are available on request by special arrangement.`;

  const treatments = [
    {
      name: "Reflexology",
      desc: "An amazing holistic therapy usually performed on the feet or hands.  It focuses on the body and mind to create balance encouraging deep relaxation and calm.",
      length: "45 mins",
      price: "£40"
    },
    {
      name: "Full Body Massage",
      desc: "Using Swedish Massage Techniques this treatment provides a relaxing experience, helping improve circulation, ease aches and pains whilst enhancing your overall wellbeing.",
      length: "1 hour",
      price: "£40"
    },
    {
      name: "Neck, Back & Shoulder Massage",
      desc: "A massage concentrating on the neck, back and shoulders using Swedish Massage Techniques to help relax your body, calm your mind and heal your spirit.",
      length: "30 mins",
      price: "£25"
    },
    {
      name: "Indian Head Massage",
      desc: "A traditional massage, given whilst seated in a chair to the back, neck, shoulders, face and scalp based on ancient Ayurveda Massage techniques. It works to relieve stress and tension culminating in a feeling of wellbeing and relaxation.",
      length: "30 mins",
      price: "£25"
    }
  ]

  return (
    <div className="Treatments">
     <div className="TreatmentsInfo">
      <p>{info1}</p>
      <p>{info2}</p>
      <p>{info3}</p>
     </div>
     <div className="TreatmentsList">
      {treatments.map((t) => {
        return (
        <div key={t.name} className="TreatmentItem">
          <h3>{t.name}</h3>
          <p>{t.desc}</p>
          <p>{t.length} | <span>{t.price}</span></p>
        </div>
        )
      })}
     </div>
    </div>
  );
}

export default Treatments;